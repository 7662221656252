import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import BlueEmail from "../assets/images/icon/blue-email.inline.svg"
import Book from "../assets/images/icon/book.inline.svg"
import Email from "../assets/images/icon/email.inline.svg"
import Phone from "../assets/images/icon/phone-black.inline.svg"
import WhatsApp from "../assets/images/icon/whatsapp.inline.svg"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Contact = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <section>
        <iframe
          title="Locatie van Eerstmediation kantoor"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d316340.70987712755!2d5.826318!3d51.725503!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x16c7ca816ead9cc2!2sEerstmediation.nl!5e0!3m2!1sen!2sus!4v1644327299521!5m2!1sen!2sus"
          className="h-350px w-full mt-70px"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-900px mx-auto grid grid-cols-1 md:grid-cols-3 items-center">
          <div className="p-10px flex flex-col items-center">
            <div className="w-70px h-70px mb-15px">
              <Phone />
            </div>
            <Link
              to="/"
              className="cursor-pointer text-link text-lg inline-block mb-15px"
            >
              06 14 06 55 30
            </Link>
          </div>
          <div className="p-10px flex flex-col items-center">
            <div className="w-70px h-70px mb-15px">
              <BlueEmail />
            </div>
            <Link
              to="mailto:riannehillenaar@eerstmediation.nl"
              className="cursor-pointer text-link text-lg inline-block mb-15px"
            >
              riannehillenaar@eerstmediation.nl
            </Link>
          </div>
          <div className="p-10px flex flex-col items-center">
            <div className="w-70px h-70px mb-15px">
              <WhatsApp />
            </div>
            <span className="text-lg text-primary inline-block mb-15px font-semibold">
              06 14 06 55 30
            </span>
          </div>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-600px mx-auto flex flex-col md:flex-row text-center items-center">
          <div className="w-1/2 p-10px flex flex-col items-center">
            <div className="h-16 w-16 mb-5">
              <Book />
            </div>
            <h3 className="mb-5 text-2xl text-primary font-semibold">
              Bezoekadres
            </h3>
            <p className="text-lg text-primary">
              Grotestraat 4
              <br />
              5437 AS Beers
              <br />
              Land van Cuijk
            </p>
          </div>
          <div className="w-1/2 p-10px flex flex-col items-center">
            <div className="h-16 w-16 mb-5">
              <Email />
            </div>
            <h3 className="mb-5 text-2xl text-primary font-semibold">
              Postadres
            </h3>
            <p className="text-lg text-primary">
              Hertog Karellaan 36
              <br />
              5431 GX Cuijk
            </p>
          </div>
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-600px mx-auto">
          <div className="max-h-450px mb-10px">
            <StaticImage
              src="../assets/images/kantoor.jpeg"
              placeholder="blurred"
            />
          </div>
          <p className="text-lg text-primary text-center">
            Eerstmediation.nl is goed bereikbaar per auto en openbaar vervoer.{" "}
            <br />
            <br />
            <strong>Met de auto:</strong>
            <br />
            Vanaf de A73 neemt u afslag 4 richting Beers/Grave (N321). Na 1,5 km
            slaat u na de kerk linksaf. Neem meteen weer de eerste weg links, u
            bent nu in de Grotestraat. Eerstmediation is gevestigd op nummer 4
            aan uw rechterhand. In de buurt kunt u gratis parkeren. <br />
            <br />
            <strong>Met het openbaar vervoer:</strong>
            <br />
            Vanaf station Cuijk neemt u Buurtbus 238 richting Grave. Deze
            vertrekt elk uur om 26 minuten over het hele uur. U stapt uit bij
            halte ‘Kerk’ in Beers. De busrit duurt 15 minuten. De halte is
            tegenover het kantoor. <br />
            De bus terug naar station Cuijk vertrekt elk uur om 19 minuten over
            het hele uur.
          </p>
        </div>
      </section>

      <section className="py-75px px-5 bg-secondary">
            <div className="max-w-900px mx-auto text-center">
                <h2 className="text-27px text-primary mb-10px">
                Kantooruren
                </h2>
                <p className="text-lg text-primary">
                <strong>Maandag t/m vrijdag</strong> van <strong>9:00-17:30</strong> 
                </p>
            </div>
      </section>
    </Layout>
  )
}

export default Contact
